import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/service',
		name: 'service',
		component: () => import('../views/Frame.vue'),
		children: [
			{
				name: 'overview',
				path: '',
				component: require('../views/Service.vue').default,
			},
		]
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('../views/Frame.vue'),
		children: [
			{
				name: 'overview',
				path: '',
				component: require('../views/Contact.vue').default,
			},
		]
	},
	{
		path: '/tools',
		name: 'tools',
		component: () => import('../views/Frame.vue'),
		children: [
			{
				name: 'overview',
				path: '',
				component: require('../views/tools/list.vue').default,
			},
			{
				name: 'MixString',
				path: 'mixString',
				component: require('../views/tools/MixString.vue').default,
			},
			{
				name: 'Naesin',
				path: 'naesin',
				component: require('../views/tools/Naesin.vue').default,
			},
			{
				name: 'SSLCertificationExpireDate',
				path: 'ssl-certification-expire-date',
				component: require('../views/tools/SSLCertificationExpireDate.vue').default,
			},
			{
				name: 'others',
				path: ':name',
				component: require('../views/tools/list.vue').default,
			}
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
