<template>
	<div>
		<br>
		<div class="container">
			<div class="box">
				<b-field label="섞을 문장을 입력해 주세요.">
					<b-input placeholder="섞을 문장을 입력해 주세요." type="textarea" v-model="mixInput" id="inputTextarea"></b-input>
				</b-field>
				<b-field grouped>
					<b-field>
						<b-button type="is-primary" @click="doMix()">섞기</b-button>
					</b-field>
					<b-field>
						<p class="control">
							<span class="button is-static">
								나누기 
							</span>
						</p>
						<b-input v-model="mixSplit" placeholder="나누기 문자" type="textarea" id="SplitTextarea" style="width: 80px"></b-input>
					</b-field>
					<b-field>
						<p class="control">
							<span class="button is-static">
								합치기 
							</span>
						</p>
						<b-input v-model="mixJoin" placeholder="합치기 문자" style="width: 80px"></b-input>
					</b-field>
				</b-field>
			</div>
			<div class="box" v-show="mixResult != ''">
				<b-field label="섞인 문장입니다: ">
					<b-input type="textarea" v-model="mixResult" size="is-small"></b-input>
				</b-field>
			</div>
			<Adsense
                data-ad-client="ca-pub-8959064465854631"
                data-ad-slot="1234567890">
            </Adsense>
		</div>
	</div>
</template>
<style>
	textarea#inputTextarea{ 
		height: 300px;
	}
	textarea#SplitTextarea{
		height: 36px;
		padding: 5px;
		min-height: 36px;
	}
</style>
<script>
export default {
	created () {
		document.title = "ESukmean's - 글 섞기";
	},

	data() {
		return {
			mixInput: '',
			mixResult: '',
			mixSplit: ' ',
			mixJoin: ' / ',
		}
	},
	methods: {
		doMix(){
			var arr = this.mixInput.split(this.mixSplit);
			var length = arr.length;

			for(var i = 0; i < length; i++){
				let pos = Math.round(Math.random() * length);

				let swap = arr[i];
				arr[i] = arr[pos];
				arr[pos] = swap;
			}
			
			this.mixResult = arr.join(this.mixJoin).trim();
		}
	}
}
</script>