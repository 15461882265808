<template>
	<div>
		<section class="hero is-dark is-medium">
			<div class="hero-body">
				<div class="container">
					<h1 class="title" style="line-height: 2; font-size: 50px">
						ESukmean's 서비스 / 프로그램들
					</h1>
					<h2 class="subtitle">
						내가 불편해서 만든<br>핸드메이드 "내가 쓸 프로그램"들. 
					</h2>
				</div>
			</div>
		</section>
		<div class="container has-text-centered" id="service_list">
			<br>
			<h2 class="subtitle has-text-weight-bold is-size-3">- 작동 가능한 서비스 / 프로그램들 -</h2>
			<div class="columns is-multiline is-centered">
				<div class="column is-half" :key="index" v-for="(service, index) in ServiceAlive">
					<div class="box" v-if="service.href == null">
						<h2 class="subtitle has-text-weight-bold">{{ service.name }}</h2>
						<p class="description" v-html="service.description"></p>
						<small>{{ service.updated }}</small>
					</div>
					<a class="box" target="_blank" :href="service.href" v-else>
						<h2 class="subtitle has-text-weight-bold">{{ service.name }}</h2>
						<p class="description" v-html="service.description"></p>
						<small>{{ service.updated }}</small>
					</a>
				</div>
			</div>
			<p>
				Github <a href="https://github.com/ESukmean?tab=repositories">@ESukmean</a> · Bitbucket <a href="https://bitbucket.org/ESukmean/">@ESukmean</a><br>
				그 외, 비공개 프로그램 다수 존재
			</p>
			<br><br><br>
			<h2 class="subtitle has-text-weight-bold is-size-3">- 관 속에 들어간 서비스 / 프로그램들 -</h2>
			<div class="columns is-multiline is-centered">
				<div class="column is-one-third" :key="index" v-for="(service, index) in ServiceDead">
					<div class="box" v-if="service.href == null">
						<h2 class="subtitle has-text-weight-bold">{{ service.name }}</h2>
						<p class="description" v-html="service.description"></p>
						<small>{{ service.updated }}</small>
					</div>
					<a class="box" target="_blank" :href="service.href" v-else>
						<h2 class="subtitle has-text-weight-bold">{{ service.name }}</h2>
						<p class="description" v-html="service.description"></p>
						<small>{{ service.updated }}</small>
					</a>
				</div>
			</div>
			<p>그 외, 연습삼아 만들어본 프로그램 및 비공개 프로그램 다수 존재</p>
			<br>
			<br>
			<br>
			<router-link to="/contact">ESukmean에게 연락하기</router-link>
		</div>
		<br>
	</div>
</template>
<style>
#service_list div.box:hover{
	box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px #7957d5
}

.description {
	line-height: 1.1;
	padding-bottom: 8px
}
</style>
<script>
export default {
	created () {
		document.title = "ESukmean's - 서비스";
	},

	data() {
		return{
			ServiceAlive: [
				{ 
					name: 'Outbound Port Checker',
					description: 'C#으로 만든 Outbound Port 확인기<br>PortQuiz.net을 사용<br>',
					href: 'http://www.esukmean.com/programs/data/program/OBPCr.zip',
					updated: '2017-03-30'
				},
				{
					name: '리눅스 사지방 도우미',
					description: '리눅스 사지방에서도 유튜브 들어가자!<br>DoH(Dns-Over-HTTPS)를 이용한 사지방 최적화 DNS서버<br>',
					href: 'https://roka.esukmean.com/',
					updated: '2019-10-17 ~ '
				},
				{
					name: '생방송 링크 공유',
					description: '생방송 채팅창에 링크를 못올린다고?<br>그럼 공유코드를 이용해서 페이지를 공유하자!',
					href: 'https://link.esukmean.com/',
					updated: '2020-08-01 ~ '
				}
			],
			ServiceDead: [
				{ 
					name: 'OsuBacker',
					description: 'Osu! 온라인 백업 서비스<br>컴퓨터가 바뀌어도 비트맵은 그대로',
					href: null,
					updated: '~ 2017-01-02'
				},{ 
					name: '렌덤 문자열 섞기',
					description: '문장을 자유자재로 섞어줍니다<br>시험 출제할 때 애용받는 프로그램',
					href: 'http://www.esukmean.com/programs/data/program/mixing_words2.zip',
					updated: '2012-02-15 ~ 2016-08-23'
				},{ 
					name: 'Remote Sleep',
					description: '컴퓨터 꺼야하는데 일어나기 싫을 때<br>폰으로 컴퓨터를 종료해 보자',
					href: null,
					updated: '2014-11-16 ~ 2015-10-07'
				},{ 
					name: 'Mail2Blog',
					description: '이메일로 블로그에 글 쓰기<br>블로그보다 이메일 에디터가 더 편할때',
					href: null,
					updated: '2014-07-21 ~ 2016-03-01'
				},{ 
					name: 'Auto ScreenShot',
					description: '컴퓨터를 몰래 감시해 보자<br>자동 스크린샷으로 뭘 했는지 알아봅시다.',
					href: null,
					updated: '2012-06-26 ~ 2015-10-12'
				},{ 
					name: 'UChat Chatting Logging Bot',
					description: '유챗1 채팅 로깅 프로그램<br>뉴스기사 표시, 풍성한 부가기능까지',
					href: null,
					updated: '2013-11-13'
				}, {
					name: 'Host Checker',
					description: '내 컴퓨터에 피싱 프로그램이?<br>Host 파일 검사 부터 DNS요청 확인까지',
					href: null,
					updated: '2013-07-27'
				}, {
					name: '복사&붙여넣기',
					description: '텍스트 입력을 도와주는 매크로<br>독서지원시스템 매크로가 되버렸지만..',
					href: null,
					updated: '2011-11-09'
				}, {
					name: 'Clipboard To Image',
					description: 'Ctrl+PrintScreen를 바로 저장<br>빠르게 캡쳐하고 손쉽게 저장한다',
					href: null,
					updated: '2012-03-20'
				}
			]
		} 
	}
}
</script>
