<template>
	<div>
		<br>
		<div class="container">
			<div class="box">
				<div style="width: 300px">
					<b-field label="학생 총원: ">
						<b-field>
							<b-numberinput v-model="number" min="0"></b-numberinput>
						</b-field>
					</b-field>
					<br>
					<div class="content">
						<ul>
							<li v-for="(people, index) in rank" :key="index">
								{{index + 1}}등급: {{people}}명 까지
							</li>
						</ul>
					</div>
				</div>
			</div>
			<Adsense
                data-ad-client="ca-pub-8959064465854631"
                data-ad-slot="1234567890">
            </Adsense>
		</div>
	</div>
</template>
<script>
export default {
	created () {
		document.title = "ESukmean's - 내신 등수 계산기";
	},
	data() {
		return {
			number: 100,
			rank: []
		}
	},
	mounted() {
		this.calc();
	},
	methods: {
		calc: function(){
			var number = this.number;
			var calc = function(percent){
				return Math.round(number * percent * 100) / 100;
			}

			this.rank = 
				[calc(0.04), calc(0.11), calc(0.23), calc(0.40), calc(0.60), calc(0.77), calc(0.89), calc(0.96), this.number];
		}
	},
	watch:{
		number(){
			this.calc();
		}
	}
}
</script>