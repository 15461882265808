<template>
    <div class="container">
        <br>
        <div class="box content">
            <h1 class="title">다음의 방법으로 E_Sukmean(이석민)에게 연락할 수 있습니다</h1>
            <ol>
                <li>이메일 (E-Mail): <b>jshlhs1@gmail.com</b></li>
                <li>행아웃 (Hangouts): <b>jshlhs1@gmail.com</b></li>
                <li>텔레그램 (Telegram): <b><a href="tg://resolve?domain=esukmean" target="_blank">@esukmean</a></b></li>
                <li>디스코드 (Discord): <b><a href="https://discord.com/users/149556614637158400" target="_blank">ESukmean#1263</a></b></li>
                <li>길드워2 (Guild Wars 2): <b>ESukmean.3801</b> ([KOR] (United Korea)에서 찾으실 수 있습니다.)</li>
                <li>카카오톡 (Kakao Talk): <b>@esukmean</b> (읽지 않을 확률 99%)</li>
                <li>전화 (Telephone. ): <b>+82 10-9800-0336</b> 주의! 이상한 번호로 오는 전화는 안받습니다.</li>
            </ol>
            <br>
            <p>행아웃이나 이메일을 통해 메세지를 전달하는것이 가장 좋습니다.<br>* 프로그램 제작 의뢰도 받고 있습니다.</p>
            <Adsense
                data-ad-client="ca-pub-8959064465854631"
                data-ad-slot="1234567890">
            </Adsense>
        </div>
    </div>
</template>
<style>
    h1.title { font-weight: normal }
</style>
<script>
export default {
	created () {
		document.title = "ESukmean's - 연락하기";
	}
}
</script>
