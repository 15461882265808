<template>
	<div>
		<section class="hero is-dark is-small">
			<div class="hero-body">
				<div class="container">
					<h2 class="subtitle">
						서버 SSL 인증서의 만료일을 확인 할 수 있습니다. 
					</h2>
				</div>
			</div>
		</section>
		<br>
		<div class="container">
			<div class="columns">
				<div class="column">
					<div class="box">
						<h2 class="title is-5">점검할 호스트 목록</h2>
						<br>
						<b-input type="textarea" v-model="hosts" placeholder="esukmean.com
eoo.kr"></b-input>
					</div>
				</div>
				<div class="column">
					<div class="box">
						<h2 class="title is-5">점검할 포트 목록</h2>
						<br>
						<b-input type="textarea" v-model="ports" placeholder="8443
443"></b-input>
					</div>
				</div>
			</div>
			<div class="box">
				<b-button class="is-primary" v-text="state" @click="doCheck()"></b-button>
				<span v-text="countStr" style="float: right"></span>
				<br style="clear: both"><br>
				<b-table :data="checkURLs" :columns="[{ field: 'status', label: '상태' },{ field: 'addr', label: '점검할 주소' },{ field: 'msg', label: '만료일 (또는 에러메세지)' }]"></b-table>
			</div>
			<br><br><br>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			hosts: this.$route.query.hosts == undefined ? 'esukmean.com\neoo.kr' : this.$route.query.hosts,
			ports: this.$route.query.ports == undefined ? '443\n8443' : this.$route.query.ports,
			checkURLs: [],
			checkList: {},
			state: '점검시작',
			countStr: ''
		}
	},
	methods: {
		onUpdateTestSet: function() {
			if (this.state != '점검시작') return;

			let host = this.hosts.split('\n').map(line => line.trim()).filter(line => line.length > 0);
			let port = this.ports.split('\n').map(line => line.trim()).filter(line => !isNaN(line)).map(numStr => parseInt(numStr))
											.filter(line => Number.isInteger(line)).filter(num => num > 0 && num < 65536);
			this.countStr = "(" + host.length + "개 서버, 총 " + (host.length * port.length) + "개 주소)";

			let product = {};
			let list = [];
			host.forEach(host => {
				port.forEach(port => {
					product[host + ':' + port] = {
						'status': '-',
						'addr': host + ':' + port,
						'host': host,
						'port': port,
						'msg': '점검 전 입니다 '
					};
					list.push({
						'status': '-',
						'addr': host + ':' + port,
						'host': host,
						'port': port,
						'msg': '점검 전 입니다 '
					});
				})
			});

			this.checkList = product;
			this.checkURLs = list;
			
			let url = new URL(window.location.href);
			url.searchParams.set('hosts', host.join('\n'));
			url.searchParams.set('ports', port.map(num => num.toString()).join('\n'));
			window.history.pushState({ path: url.href }, '', url.href);
		},
		doCheck: function() {
			if (this.state != '점검시작') return;

			this.state = '확인 중...';
			let vue_instance = this;
			// console.log(JSON.stringify({'list': this.checkURLs}));

			fetch('/api/SSLCertificationExpireDate', {
				method: 'POST', 
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({'list': this.checkURLs})
			}).then(resp => resp.json()).then(data => {
				let reqid = data['req_id'];
				let interval_id = setInterval(function() {
					fetch('/api/SSLCertificationExpireDateResult/' + reqid).then(resp => resp.json()).then(data => {
						if (data['state'] == 'end') { clearInterval(interval_id); vue_instance.state = '점검시작'; }

						let list = [];
						for(let line in data['result']) {
							list.push(data['result'][line]);
						}

						vue_instance.checkURLs = list;
						vue_instance.$forceUpdate();
					}).catch(err => {
						alert('에러 발생: ' + err.message);
						clearInterval(interval_id);

						vue_instance.state = '점검시작';
					});
				}, 1500);
			})
		}
	},
	watch: {
		hosts: function() { this.onUpdateTestSet() },
		ports: function() { this.onUpdateTestSet() }
	},
	mounted() {
		this.onUpdateTestSet();
		document.title = "ESukmean's - SSL 인증서 만료일 확인기"
	}
}
</script>